import default_testimonial_logo from "../images/testimonial_logo.png"

export const testimonialData = [
    {
        image: default_testimonial_logo,
        content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facere quos mollitia nemo facilis possimus voluptate, odio expedita laudantium modi."
    },
    {
        image: default_testimonial_logo,
        content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facere quos mollitia nemo facilis possimus voluptate, odio expedita laudantium modi."
    },
    {
        image: default_testimonial_logo,
        content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facere quos mollitia nemo facilis possimus voluptate, odio expedita laudantium modi."
    },
]
